import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  files: [],
};

export const getFiles = createAsyncThunk("getFiles", async () => {
  const { data } = await axios.get("");
  return data;
});

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFiles.fulfilled, (state, action) => {
      state.files = action.payload;
    });
  },
});

export default filesSlice.reducer;
