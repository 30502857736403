export const az = {
  months: [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "İyun",
    "İyul",
    "Avqust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ],
  weekDays: [
    "Bazar ertəsi",
    "Çərşənbə axşamı",
    "Çərşənbə",
    "Cümə axşamı",
    "Cümə",
    "Şənbə",
    "Bazar",
  ],
  weekDaysShort: ["BE", "ÇA", "Ç", "CA", "C", "Ş", "B"],
  buttons: {
    addAppointment: "Görüş əlavə et",
    today: "Bu gün",
    month: "Ay",
    week: "Həftə",
    day: "Gün",
    year: "İl",
    save: "Yadda saxla",
    cancel: "Ləğv et",
    delete: "Sil",
  },
  modal: {
    newAppointment: "Yeni görüş",
    editAppointment: "Görüşü redaktə et",
    patientName: "Xəstənin adı və soyadı",
    patientID:"Pasiyentin identifikasiya nömrəsi",
    date: "Tarix",
    time: "Vaxt",
    duration: "Müddət",
    type: "Növ",
    notes: "Qeydlər",
    appointmentNotes: "Müraciət barədə qeydlər",
    personalNotes:"Personal üçün qeydlər",
  },
  appointmentTypes: {
    checkup: "Müayinə",
    consultation: "Konsultasiya",
    surgery: "Cərrahi əməliyyat",
    followup: "Təkrar müayinə",
  },
};
