import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { X } from "lucide-react";
import {
  toggleModal,
  addAppointment,
  removeAppointment,
  setSelectedAppointment,
} from "../../redux/features/scheduleStore/calendarSlice";
import { az } from "../localization/az";
import laSound from './calendarsound.wav';

function playSound() {
  const audio = new Audio(laSound);
  audio.play();
  audio.onended = () => {
    audio.src = "";
  };
}
export const AppointmentModal = () => {
  const dispatch = useDispatch();
  const { isModalOpen, selectedDate, selectedAppointment } = useSelector(
    (state) => state.calendar
  );

  const [formData, setFormData] = useState({
    patientID:"",
    patientName: "",
    date: selectedDate,
    time: "00:00",
    duration: 30,
    type: "checkup",
    notes: "",
    personalNotes: "",
  });

  useEffect(() => {
    if (selectedAppointment) {
      setFormData({
        patientID:selectedAppointment.patientID,
        patientName: selectedAppointment.patientName,
        time: selectedAppointment.time || "00:00",
        duration: selectedAppointment.duration || 30,
        type: selectedAppointment.type || "checkup",
        notes: selectedAppointment.notes || "",
        personalNotes: selectedAppointment.personalNotes || "",
      });
    }else if (selectedDate) {
      setFormData({
        patientID:'',
        patientName: '',
        date: selectedDate,
        time: new Date(selectedDate).toTimeString().slice(0, 5),
        duration: 30,
        type: 'checkup',
        notes: '',
        personalNotes: "",
      });
    }
  }, [selectedAppointment,selectedDate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const appointment = {
      ...formData,
    };

    console.log(appointment);

    dispatch(addAppointment(appointment));
    dispatch(setSelectedAppointment(null));
    dispatch(toggleModal(false));
    playSound();
  };

  if (!isModalOpen) return null;

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0 && remainingMinutes > 0) {
      return `${hours} saat ${remainingMinutes} dəqiqə`;
    } else if (hours > 0) {
      return `${hours} saat`;
    } else {
      return `${remainingMinutes} dəqiqə`;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto sm:overflow-y-visible pb-20 pt-40">
      <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-md sm:max-w-3xl mx-4 sm:mt-0 mt-60">
        <div className="flex justify-between items-center mb-4 sm:mb-6">
          <h3 className="text-lg sm:text-xl font-bold text-gray-800">
            {az.modal.newAppointment}
          </h3>
          <button
            onClick={() => dispatch(toggleModal(false))}
            className="text-gray-500 hover:text-gray-700">
            <X className="w-5 sm:w-6 h-5 sm:h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {az.modal.patientID}
            </label>
            <input
              type="text"
              value={formData.patientID}
              onChange={(e) =>
                setFormData({ ...formData, patientID: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {az.modal.patientName}
            </label>
            <input
              type="text"
              value={formData.patientName}
              onChange={(e) =>
                setFormData({ ...formData, patientName: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Contact Number
            </label>
            <input
              id="contactNumber"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="+994 XX XXX XX XX"
              maxLength="16"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {az.modal.time}
            </label>
            <input
              type="time"
              value={formData.time}
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                {az.modal.duration}
              </label>
              <input
                type="number"
                value={formData.duration}
                onChange={(e) =>
                  setFormData({ ...formData, duration: Number(e.target.value) })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-gray-900"
                min="0"
              />
              <div className="text-sm text-gray-500 mt-1">
                {formatDuration(formData.duration)}
              </div>
            </div>
            <button
              type="button"
              onClick={() =>
                setFormData({ ...formData, duration: formData.duration + 10 })
              }
              className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              +10 dəq
            </button>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              {az.modal.type}
            </label>
            <select
              value={formData.type}
              onChange={(e) =>
                setFormData({ ...formData, type: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500">
              {Object.entries(az.appointmentTypes).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {az.modal.appointmentNotes}
            </label>
            <textarea
              value={formData.notes}
              onChange={(e) =>
                setFormData({ ...formData, notes: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows={3}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {az.modal.personalNotes}
            </label>
            <textarea
              value={formData.personalNotes}
              onChange={(e) =>
                setFormData({ ...formData, personalNotes: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows={3}
            />
          </div>
          <div className="col-span-1 sm:col-span-2 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => dispatch(toggleModal(false))}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
              {az.buttons.cancel}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              {az.buttons.save}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};