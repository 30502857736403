import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to simulate an API call for login
// export const login = createAsyncThunk("auth/login", async (credentials, { rejectWithValue }) => {
//   try {
//     // Replace this with an actual API call
//     const response = await new Promise((resolve, reject) =>
//       setTimeout(() => {
//         if (credentials.email === "test@example.com" && credentials.password === "password") {
//           resolve({ user: { email: credentials.email }, token: "dummy-token" });
//         } else {
//           reject(new Error("Invalid email or password"));
//         }
//       }, 1000)
//     );
//     return response;
//   } catch (error) {
//     return rejectWithValue(error.message);
//   }
// });

const initialState = {
  user: null,
  token: null,
  loading: false,
  error: null,
};

// Sample user credentials
const SAMPLE_USER = {
  username: "admin",
  password: "admin",
  role: "admin", // Optional: to differentiate user roles
  token: "sample-token", // Optional: a fake token for testing
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      const { mail, password } = action.payload;
      console.log(action.payload);
      console.log(password);
      if (mail == SAMPLE_USER.username && password == SAMPLE_USER.password) {
        state.user = SAMPLE_USER;
        state.token = SAMPLE_USER.token;

        // Store in localStorage (optional)
        localStorage.setItem("user", JSON.stringify(SAMPLE_USER));
        localStorage.setItem("token", SAMPLE_USER.token);
      } else {
        state.error = "Invalid credentials";
      }
    },
    logout(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
