import React, { useState, useEffect } from "react";
import doctor from "../images/doctor.png";
import appointment from "../images/appointment.png";
import patient from "../images/patient.png";
import "./Dashboard.css";
import "../animations/animation.css";
import MonthlyPatientChart from "./companents/MonthlyPatientChart";
import { useDispatch, useSelector } from "react-redux";
import { getBookings } from "../redux/features/dashboardStore/bookingsSlice";
import StatsCard from "./companents/StatsCard";
import { Activity, Calendar, Clock, Users } from "lucide-react";
import BookingItem from "./companents/BookingItem";

export default function Dashboard() {
  const dispatch = useDispatch();

  const bookings = useSelector((state) => state.bookings.bookings) || [];

  useEffect(() => {
    dispatch(getBookings());
  }, [dispatch]);
  console.log(bookings);


  return (
    <div className="main">
      <div className="space-y-6 flex-2 p-8 w-full">
        <div className="flex flex-wrap justify-center gap-6 w-full">
          <StatsCard
            title="Total Patients"
            value="1,234"
            icon={<Users className="w-6 h-6" />}
            trend="+0%"
          />
          <StatsCard
            title="Today's Appointments"
            value="8"
            icon={<Calendar className="w-6 h-6" />}
            trend="+0"
          />
          <StatsCard
          title="Total Appointments"
          value="20"
          icon={<Calendar className="w-6 h-6" />}
          trend="+0"
        />
  
          <StatsCard
            title="Available Time Slots"
            value="3"
            icon={<Clock className="w-6 h-6" />}
            trend="+0"
          />
        </div>
      </div>

      {/* My Tailwind booking list */}
      <section className="flex flex-wrap justify-center items-start gap-6 w-full">
        <BookingItem bookings={bookings}></BookingItem>
        <MonthlyPatientChart></MonthlyPatientChart>
      
      </section>
      

      <footer></footer>
    </div>
  );
}
