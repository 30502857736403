import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function PatientInfo(booking) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  // Function to check screen size
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // useEffect to listen for screen size changes
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to handle clicks on the patient item
  const handlePatientClick = () => {
    if (isMobile) {
      navigate(`/patients/${booking.id}`);
    }
  };

  return (
    <div className="patientItemWrapper">
      <div
        key={booking.id}
        className="patientItem"
        onClick={handlePatientClick}>
        <div className="patientInfo">
          <img src={booking.imgSrc} alt={booking.name} className="patientImg" />
          <div className="patientDetails">
            <p>{booking.name}</p>
            <p className="patient-id">ID: {booking.code}</p>
          </div>
          <p className="patientGender">
            {booking.gender === "Male" ? (
              <i
                className="edit-pen-title fa fa-mars"
                style={{ color: "#007bff", fontSize:"1.5em" }}></i>
            ) : (
              <i
                className="edit-pen-title fa fa-venus"
                style={{ color: "#FF1493", fontSize:"1.5em" }}></i>
            )}
          </p>
          <p className="patientDateTime">
            {booking.date} <br /> {booking.time}
          </p>

          {/* Show the button only if not on mobile */}
          {!isMobile && (
            <Link to={`/patients/${booking.id}`}>
              <button className="viewMoreBtn">View More</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
