import React, { useState, useEffect } from 'react';

export default function MiniClock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  return (
    <div className="relative w-full aspect-square max-w-[120px] mx-auto">
      <div className="absolute inset-0 rounded-full border-4 border-blue-100">
        {/* Hour markers */}
        {Array.from({ length: 12 }).map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-3 bg-blue-200"
            style={{
              transform: `rotate(${i * 30}deg)`,
              transformOrigin: '50% 50%',
              left: '50%',
              marginLeft: '-2px',
            }}
          />
        ))}
        
        {/* Clock hands */}
        <div
          className="absolute h-[35%] w-1 bg-blue-600 rounded-full"
          style={{
            top: '15%',
            left: '50%',
            transformOrigin: 'bottom',
            transform: `translateX(-50%) rotate(${(hours % 12) * 30 + minutes / 2}deg)`,
          }}
        />
        <div
          className="absolute h-[40%] w-1 bg-blue-500 rounded-full"
          style={{
            top: '10%',
            left: '50%',
            transformOrigin: 'bottom',
            transform: `translateX(-50%) rotate(${minutes * 6}deg)`,
          }}
        />
        <div
          className="absolute h-[45%] w-0.5 bg-red-500 rounded-full"
          style={{
            top: '5%',
            left: '50%',
            transformOrigin: 'bottom',
            transform: `translateX(-50%) rotate(${seconds * 6}deg)`,
          }}
        />
        <div className="absolute w-3 h-3 bg-blue-600 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>
  );
}
