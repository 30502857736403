import React, { useState, useEffect, useContext } from "react";
import "./Summary.css";
import { Activity, Bell, Users } from "lucide-react";
import MiniClock from "./components/MiniClock";

const reminders = [
  {
    id: 1,
    title: "Staff Meeting",
    time: "11:00 AM",
    priority: "high",
  },
  {
    id: 2,
    title: "Review Lab Results",
    time: "02:30 PM",
    priority: "medium",
  },
  {
    id: 3,
    title: "Update Patient Records",
    time: "04:00 PM",
    priority: "low",
  },
];

const Summary = () => {
  return (
    <div className="summary-container">
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-800">
            Quick Overview
          </h2>
          <span className="text-sm text-blue-600">Today</span>
        </div>
        <MiniClock />
      </div>
      <div className="bg-white p-6 rounded-xl shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">Reminders</h2>
          <Bell className="w-5 h-5 text-gray-400" />
        </div>
        <div className="space-y-3">
          {reminders.map((reminder) => (
            <div
              key={reminder.id}
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div>
                <p className="font-medium text-gray-900">{reminder.title}</p>
                <p className="text-sm text-gray-500">{reminder.time}</p>
              </div>
              <span
                className={`w-2 h-2 rounded-full
                ${
                  reminder.priority === "high"
                    ? "bg-red-500"
                    : reminder.priority === "medium"
                    ? "bg-yellow-500"
                    : "bg-green-500"
                }
              `}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-500 mb-3">Today's Analytics</h3>
        <div className="grid grid-cols-2 gap-3">
          <div className="bg-green-50 p-3 rounded-lg">
            <div className="flex items-center gap-2 mb-1">
              <Users className="w-4 h-4 text-green-600" />
              <span className="text-sm text-gray-600">Patients</span>
            </div>
            <p className="text-lg font-semibold text-gray-800">24</p>
          </div>
          <div className="bg-purple-50 p-3 rounded-lg">
            <div className="flex items-center gap-2 mb-1">
              <Activity className="w-4 h-4 text-purple-600" />
              <span className="text-sm text-gray-600">Critical</span>
            </div>
            <p className="text-lg font-semibold text-gray-800">3</p>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-500 mb-3">Staff On Call</h3>
        <div className="space-y-2">
          {[
            { name: 'Dr. Johnson', specialty: 'Emergency', status: 'Available' },
            { name: 'Dr. Patel', specialty: 'Surgery', status: 'In Surgery' },
          ].map((staff, index) => (
            <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
              <div>
                <p className="text-sm font-medium text-gray-800">{staff.name}</p>
                <p className="text-xs text-gray-500">{staff.specialty}</p>
              </div>
              <span className={`text-xs px-2 py-1 rounded-full ${
                staff.status === 'Available' ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'
              }`}>
                {staff.status}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Summary;
