import React, { useState } from "react";

export default function BookingItem({ bookings}) {

  return (
    <div>
      <div className="NNBooking w-full px-3 flex flex-col md:flex-row">
        <div className="">
          <div className="p-5 max-w-md bg-white rounded-lg border shadow-md mt-0 sm:p-5 bg-gray-50 m-1">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold leading-none text-gray-900">
                Latest Customers
              </h3>
              <a
                href="/patients"
                className="text-sm font-medium text-blue-600 hover:underline">
                View all
              </a>
            </div>
            <div>
              <div className="h-[400px] overflow-y-scroll">
                <ul role="list" className="divide-y divide-gray-200">
                  {bookings.map((booking) => (
                    <li className="py-3 sm:py-4" key={booking.id}>
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full"
                            src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                            alt={booking.name}
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {booking.name}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {booking.date}
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900">
                          Age: {booking.age}
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900">
                          {booking.time} 🕑
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
