import React from "react";
import "./Payments.css";
export default function Payments() {
  return (
    <div className="search-container">
      <form className="search-form">
        <input
          type="search"
          value=""
          placeholder="Search"
          class="search-input"
        />
        <div className="search-button">
          <i
            className="fa-solid fa-magnifying-glass"
            style={{ color: "#007bff" }}></i>
        </div>

        <div className="search-option">
          <div>
            <input
              name="type"
              type="radio"
              value="type-users"
              id="type-users"
            />
            <label for="type-male">
              <i
                class="edit-pen-title fa fa-mars"
                style={{ color: "#007bff" }}></i>
              <span>Male</span>
            </label>
          </div>

          <div>
            <input
              name="type"
              type="radio"
              value="type-posts"
              id="type-posts"
            />
            <label for="type-female">
              <i
                class="edit-pen-title fa fa-venus"
                style={{ color: "#007bff" }}></i>
              <span>Female</span>
            </label>
          </div>

          <div>
            <input
              name="type"
              type="radio"
              value="type-posts"
              id="type-posts"
            />
            <label for="type-filters">
              <i
                class="edit-pen-title fa fa-sort"
                style={{ color: "#007bff" }}></i>
              <span>Filters</span>
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}
