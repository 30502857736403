import React, { useState } from 'react';
import { FaFilePdf } from 'react-icons/fa'; // Import FontAwesome icon for PDF
import './FilePreviewer.css';

function FilePreviewer() {
  const [files, setFiles] = useState([]); // State to store selected files
  const [uploadProgress, setUploadProgress] = useState({}); // State to track upload progress
  const [isDragging, setIsDragging] = useState(false); // State to track drag status

  const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB

  // Handle file selection with type and size restriction
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    
    selectedFiles.forEach((file) => {
      if (
        (file.type.startsWith("image/") || file.type === "application/pdf") &&
        file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      ) {
        validFiles.push(file);
      } else {
        alert(`File "${file.name}" is either too large (over 10 MB) or an unsupported type. Please upload only PDF or image files.`);
      }
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  // Handle file drop with type and size restriction
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    const validFiles = [];

    droppedFiles.forEach((file) => {
      if (
        (file.type.startsWith("image/") || file.type === "application/pdf") &&
        file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      ) {
        validFiles.push(file);
      } else {
        alert(`File "${file.name}" is either too large (over 10 MB) or an unsupported type. Please upload only PDF or image files.`);
      }
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  // Simulate file upload progress
  const simulateUpload = (fileIndex) => {
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [fileIndex]: 0,
    }));

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        const currentProgress = prevProgress[fileIndex] || 0;
        if (currentProgress >= 100) {
          clearInterval(interval);
          return prevProgress;
        }
        return {
          ...prevProgress,
          [fileIndex]: currentProgress + 10,
        };
      });
    }, 300); // Adjust the speed of the upload
  };

  // Start upload for all files
  const handleUpload = () => {
    files.forEach((_, index) => simulateUpload(index));
  };

  // Remove file from the list
  const handleRemoveFile = (fileIndex) => {
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
    setUploadProgress((prevProgress) => {
      const updatedProgress = { ...prevProgress };
      delete updatedProgress[fileIndex];
      return updatedProgress;
    });
  };

  return (
    <div
      style={{ width: "100%" }}
      onDrop={handleDrop}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onDragLeave={() => setIsDragging(false)}
      className={`p-4 ${isDragging ? 'border-blue-600' : ''}`}
    >
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-input"
        accept="image/*, application/pdf"
      />
      <label htmlFor="file-input" className="cursor-pointer p-12 flex justify-center bg-white border border-dashed border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600">
        <div className="text-center">
          <span className="inline-flex justify-center items-center size-16 bg-gray-100 text-gray-800 rounded-full dark:bg-neutral-700 dark:text-neutral-200">
            <svg className="shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" x2="12" y1="3" y2="15"></line>
            </svg>
          </span>

          <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
            <span className="pe-1 font-medium text-gray-800 dark:text-neutral-200">Drop your file here</span>
          </div>

          <p className="mt-1 text-xs text-gray-400 dark:text-neutral-400">Only PDF and image files allowed, max 10 MB per file.</p>
        </div>
      </label>

      <div className="mt-4 space-y-2">
        {files.map((file, index) => {
          const fileNameParts = file.name.split('.');
          const extension = fileNameParts.length > 1 ? fileNameParts.pop() : ''; // Get only the last extension part
          const fileName = fileNameParts.join('.'); // Join the remaining parts as the file name

          return (
            <div key={index} className="p-3 bg-white border border-solid border-gray-300 rounded-xl dark:bg-neutral-800 dark:border-neutral-600">
              <div className="mb-1 flex justify-between items-center">
                <div className="flex items-center gap-x-3">
                  <span className="size-10 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700 dark:text-neutral-500">
                    {/* Render image thumbnail or PDF icon */}
                    {file.type.startsWith("image/") ? (
                      <img src={URL.createObjectURL(file)} alt={fileName} className="w-10 h-10 rounded-lg" />
                    ) : (
                      <FaFilePdf className="text-red-500 w-8 h-8" />
                    )}
                  </span>
                  <div>
                    <p className="text-sm font-medium text-gray-800 dark:text-white">
                      <span className="truncate inline-block max-w-[300px] align-bottom">{fileName}</span>.<span>{extension}</span>
                    </p>
                    <p className="text-xs text-gray-500 dark:text-neutral-500">{(file.size / 1024).toFixed(2)} KB</p>
                  </div>
                </div>
                <button type="button" onClick={() => handleRemoveFile(index)} className="text-gray-500 hover:text-gray-800 dark:text-neutral-500 dark:hover:text-neutral-200">
                  <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 6h18"></path>
                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                    <line x1="10" x2="10" y1="11" y2="17"></line>
                    <line x1="14" x2="14" y1="11" y2="17"></line>
                  </svg>
                </button>
              </div>
              <div className="flex items-center gap-x-3 whitespace-nowrap">
                <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
                  <div
                    className="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition-all duration-500 hs-file-upload-complete:bg-green-500"
                    style={{ width: `${uploadProgress[index] || 0}%` }}
                  />
                </div>
                <div className="w-10 text-end">
                  <span className="text-sm text-gray-800 dark:text-white">{uploadProgress[index] || 0}%</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button onClick={handleUpload} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">Upload Files</button>
    </div>
  );
}

export default FilePreviewer;