import React from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { Calendar, Plus } from "lucide-react";
import { store } from "../redux/app/store";
import { MonthView } from "./components/Monthview";
import { WeekView } from "./components/WeekView";
import { DayView } from "./components/DayView";
import { AppointmentModal } from "./components/AppointmentModal";
import {
  setView,
  toggleModal,
  setSelectedDate,
} from "../redux/features/scheduleStore/calendarSlice";
import { az } from "./localization/az";

const CalendarApp = () => {
  const dispatch = useDispatch();
  const { view, selectedDate } = useSelector((state) => state.calendar);

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          {/* Header content (optional) */}
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-6 flex justify-between items-center">
          <div className="flex space-x-2">
            <button
              onClick={() => dispatch(setSelectedDate(new Date()))}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md border border-gray-300 hover:bg-gray-50">
              {az.buttons.today}
            </button>
            <div className="border-l border-gray-300 mx-2" />
            <div className="flex flex-col sm:flex-row rounded-md shadow-sm space-y-2 sm:space-y-0">
              <div className="flex rounded-md">
                {["month", "week", "day"].map((viewType) => (
                  <button
                    key={viewType}
                    onClick={() => dispatch(setView(viewType))}
                    className={`
          px-3 sm:px-4 py-2 text-sm font-medium
          ${
            view === viewType
              ? "bg-blue-500 text-white"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }
          ${viewType === "month" ? "rounded-l-md" : ""}
          ${viewType === "day" ? "rounded-r-md" : ""}
          border border-gray-300
          -ml-px first:ml-0
        `}>
                    {az.buttons[viewType]}
                  </button>
                ))}
              </div>

              <button
                onClick={() => {
                  dispatch(setSelectedDate(new Date()));
                  dispatch(toggleModal(true));
                }}
                className="
      flex items-center justify-center space-x-2 px-3 sm:px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors 
      sm:ml-2 w-full sm:w-auto
    ">
                <Plus className="w-5 h-5" />
                <span className="text-xs sm:text-sm">
                  {az.buttons.addAppointment}
                </span>
              </button>
            </div>
          </div>
        </div>

        {view === "month" && <MonthView />}
        {view === "week" && <WeekView />}
        {view === "day" && <DayView />}
      </main>

      <AppointmentModal />
    </div>
  );
};

function Schedule() {
  return (
    <Provider store={store}>
      <CalendarApp />
    </Provider>
  );
}

export default Schedule;
