import React, { useState,useRef,useEffect } from 'react';
import './Settings.css';

const Settings = () => {

  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [ctx, setCtx] = useState(null);

  const [profileDetails, setProfileDetails] = useState({
    city: '',
    website: '',
    bio: '',
  });

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const [profilePhoto, setProfilePhoto] = useState(null);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const saveProfileDetails = () => {
    // Save profile details logic
  };

  const savePassword = () => {
    // Save password logic
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    setCtx(canvas.getContext("2d"));
  }, []);

  const getMousePos = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect(); // Get the canvas position and size

    // Mouse events
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Touch events (for mobile)
    if (e.touches) {
      const touch = e.touches[0];
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      };
    }

    return { x, y };
  };

  const startDrawing = (e) => {
    setIsDrawing(true);
    const pos = getMousePos(e); // Get the corrected mouse/touch position
    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y); // Move to the corrected coordinates
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const pos = getMousePos(e); // Get the corrected mouse/touch position
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.strokeStyle = "black";
    ctx.lineTo(pos.x, pos.y); // Draw to the corrected coordinates
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    ctx.closePath();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const saveSignature = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "signature.png";
    link.click();
  };


  return (
    <div className="settings-container">
      <div className="section">
        <h2>Profile Details</h2>
        <div className="profile-photo">
          <input
            type="file"
            id="profilePhotoInput"
            accept="image/*"
            onChange={handleProfilePhotoChange}
          />
          <label htmlFor="profilePhotoInput">
            <img
              src={profilePhoto || 'https://www.shutterstock.com/image-vector/default-placeholder-doctor-halflength-portrait-600nw-1058724875.jpg'}
              alt="Profile"
            />
          </label>
        </div>
        <div className="input-group">
          <input
            type="text"
            name="city"
            placeholder="City"
            value={profileDetails.city}
            onChange={handleProfileChange}
          />
          <input
            type="text"
            name="website"
            placeholder="Website"
            value={profileDetails.website}
            onChange={handleProfileChange}
          />
        </div>
        <div className="input-group">
          <textarea
            name="bio"
            placeholder="Bio"
            maxLength="140"
            value={profileDetails.bio}
            onChange={handleProfileChange}
          ></textarea>
        </div>
        <p>Fields you're passionate about or would like to explore.</p>
        <div className="save-button">
          <button onClick={saveProfileDetails}>Save changes</button>
        </div>
      </div>

      <div className="section">
        <h2>Change Password</h2>
        <div className="password-change">
          <input
            type="password"
            name="oldPassword"
            placeholder="Old Password"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            name="repeatNewPassword"
            placeholder="Repeat New Password"
            value={passwords.repeatNewPassword}
            onChange={handlePasswordChange}
          />
        </div>

        <div>
            <h2>Sign below:</h2>
            <div className="signature-box">
              <canvas
                ref={canvasRef}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={stopDrawing}
              />
            </div>
            <div className="buttons">
              <button onClick={clearCanvas}>Clear</button>
              <button onClick={saveSignature}>Save</button>
            </div>
          </div>

        <div className="save-button">
          <button onClick={savePassword}>Save changes</button>
        </div>
      </div>
    </div>
  );
};

export default Settings;