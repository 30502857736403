import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  startOfWeek,
  addDays,
} from 'date-fns';
import { ChevronLeft, ChevronRight, AlertCircle } from 'lucide-react';
import { setSelectedDate, toggleModal } from '../../redux/features/scheduleStore/calendarSlice';
import { az } from '../localization/az';

export const MonthView = () => {
  const dispatch = useDispatch();
  const { selectedDate, appointments } = useSelector(state => state.calendar);

  const monthStart = startOfMonth(selectedDate);
  const monthEnd = endOfMonth(selectedDate);
  const calendarStart = startOfWeek(monthStart, { weekStartsOn: 1 });
  const calendarDays = eachDayOfInterval({ start: calendarStart, end: addDays(calendarStart, 41) });

  const getAppointmentsForDay = date => {
    return appointments.filter(app => isSameDay(new Date(app.date), date)) || [];
  };

  const isDayFull = date => {
    const dayAppointments = getAppointmentsForDay(date);
    return dayAppointments.length >= 8;
  };

  const handleDayClick = (date, dayAppointments) => {
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() < today.getTime()) {
      return;
    }

    dispatch(setSelectedDate(date));
    dispatch(toggleModal(true));
  };

  const previousMonth = () => {
    const prevDate = new Date(selectedDate);
    prevDate.setMonth(selectedDate.getMonth() - 1);
    dispatch(setSelectedDate(prevDate));
  };

  const nextMonth = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setMonth(selectedDate.getMonth() + 1);
    dispatch(setSelectedDate(nextDate));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">
          {format(selectedDate, 'MMMM yyyy')}
        </h2>
        <div className="flex space-x-4">
          <button
            onClick={previousMonth}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Əvvəlki ay"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={nextMonth}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Növbəti ay"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {az.weekDaysShort.map(day => (
          <div key={day} className="text-center font-semibold text-gray-600 py-2">
            {day}
          </div>
        ))}

        {calendarDays.map(day => {
          const dayAppointments = getAppointmentsForDay(day);
          const isToday = isSameDay(day, new Date());
          const isFull = isDayFull(day);
          const isCurrentMonth = isSameMonth(day, selectedDate);

          return (
            <button
              key={day.toString()}
              onClick={() => handleDayClick(day, dayAppointments)}
              className={`
                min-h-[100px] p-2 border rounded-lg relative group
                ${isCurrentMonth ? 'bg-white' : 'bg-purple-100'}
                ${isToday ? 'border-blue-500' : 'border-gray-200'}
                ${isFull ? 'bg-red-50' : ''}
                hover:bg-gray-50 transition-colors
              `}
            >
              <div className="flex justify-between items-center mb-1">
                <span className={`
                  text-sm font-medium
                  ${isToday ? 'text-blue-500' : isCurrentMonth ? 'text-gray-700' : 'text-gray-400'}
                `}>
                  {format(day, 'd')}
                </span>
                {isFull && (
                  <AlertCircle className="w-4 h-4 text-red-500" />
                )}
              </div>
              
              <div className="space-y-1">
                {dayAppointments.slice(0, 3).map(app => (
                  <div
                    key={app.id}
                    className="text-xs p-1 rounded bg-blue-100 text-blue-800 truncate"
                  >
                    {format(new Date(app.date), 'HH:mm')} - {app.patientName}
                  </div>
                ))}
                
                {dayAppointments.length > 3 && (
                  <div className="text-xs text-gray-600 bg-gray-100 p-1 rounded text-center">
                    +{dayAppointments.length - 3} daha
                  </div>
                )}
              </div>

              {dayAppointments.length > 0 && (
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-5 rounded-lg transition-opacity" />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};
