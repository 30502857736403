import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { format, startOfWeek, addDays, isSameDay, isToday } from "date-fns";
import { ChevronLeft, ChevronRight, MoreHorizontal, Clock } from "lucide-react";
import {
  setSelectedDate,
  toggleModal,
  setSelectedAppointment,
} from "../../redux/features/scheduleStore/calendarSlice";
import { az } from "../localization/az";

export const WeekView = () => {
  const dispatch = useDispatch();
  const { selectedDate, appointments } = useSelector((state) => state.calendar);

  const weekStart = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  const getAppointmentsForDay = (date) => {
    return appointments
      .filter((app) => isSameDay(new Date(app.date), date))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };

  const handlePrevWeek = () => {
    dispatch(setSelectedDate(addDays(weekStart, -7)));
  };

  const handleNextWeek = () => {
    dispatch(setSelectedDate(addDays(weekStart, 7)));
  };

  const handleAppointmentClick = (appointment) => {
    dispatch(setSelectedAppointment(appointment));
    dispatch(toggleModal(true));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg">
      {/* Week Navigation */}
      <div className="p-4 border-b flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-bold text-gray-800 hidden sm:block">
            {format(weekStart, "MMMM yyyy")}
          </h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevWeek}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Previous week">
              <ChevronLeft className="w-5 h-5" />
            </button>
            <button
              onClick={handleNextWeek}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Next week">
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Week Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-7 divide-y sm:divide-y-0 sm:divide-x">
        {weekDays.map((day) => {
          const dayAppointments = getAppointmentsForDay(day);
          const isCurrentDay = isToday(day);

          return (
            <div key={day.toString()} className="min-h-[200px]">
              {/* Day Header */}
              <div
                className={`
                p-3 border-b text-center
                ${isCurrentDay ? "bg-blue-50" : ""}
              `}>
                <div className="text-sm font-medium text-gray-500">
                  {format(day, "EEEE")}
                </div>
                <div
                  className={`
                  text-2xl font-bold mt-1
                  ${isCurrentDay ? "text-blue-600" : "text-gray-800"}
                `}>
                  {format(day, "d")}
                </div>
              </div>

              <button
                onClick={() => {
                  dispatch(setSelectedDate(day));
                  dispatch(toggleModal(true));
                }}
                className="w-full h-20 border-2 border-dashed border-gray-200 rounded-lg hover:border-blue-300 hover:bg-blue-50 transition-colors flex items-center justify-center text-sm text-gray-500 hover:text-blue-600">
                Yeni görüş əlavə et
              </button>

              {/* Appointments List */}
              <div className="p-2 space-y-2">
                {dayAppointments.slice(0, 3).map((appointment) => (
                  <button
                    key={appointment.id}
                    onClick={() => handleAppointmentClick(appointment)}
                    className="w-full text-left p-2 rounded-lg bg-blue-50 hover:bg-blue-100 transition-colors group">
                    <div className="flex items-center space-x-2 text-sm">
                      <Clock className="w-4 h-4 text-blue-500" />
                      <span className="font-medium text-blue-600">
                        {format(new Date(appointment.date), "HH:mm")}
                      </span>
                    </div>
                    <div className="font-medium text-gray-800 truncate">
                      {appointment.patientName}
                    </div>
                    <div className="text-xs text-gray-500">
                      {az.appointmentTypes[appointment.type]}
                    </div>
                  </button>
                ))}

                {dayAppointments.length > 3 && (
                  <button
                    onClick={() => {
                      dispatch(setSelectedDate(day));
                      dispatch(toggleModal(true));
                    }}
                    className="w-full flex items-center justify-center p-2 text-sm text-blue-600 hover:bg-blue-50 rounded-lg transition-colors">
                    <MoreHorizontal className="w-4 h-4 mr-1" />
                    <span>+{dayAppointments.length - 3} daha</span>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
