import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Patients.css";
import PatientInfo from "./PatientInfo";

export default function Patients() {
  const generatePatients = () => {
    const samplePatients = [
      {
        name: "Jane Cooper",
        gender: "Female",
        imgSrc: "https://via.placeholder.com/150",
        code: "ID001",
      },
      {
        name: "John Doe",
        gender: "Male",
        imgSrc: "https://via.placeholder.com/150",
        code: "ID002",
      },
      {
        name: "Emma Stone",
        gender: "Female",
        imgSrc: "https://via.placeholder.com/150",
        code: "ID003",
      },
      {
        name: "AAANihad Axundzade",
        gender: "Male",
        imgSrc: "https://via.placeholder.com/150",
        code: "ID004",
        date: "Jun 30, 2024",
      },
      {
        name: "Lucy Liu",
        gender: "Female",
        imgSrc: "https://via.placeholder.com/150",
        code: "ID005",
      },
      {
        name: "Bruce Wayne",
        gender: "Male",
        imgSrc: "https://via.placeholder.com/150",
        code: "ID006",
      },
    ];

    let patients = [];
    for (let i = 1; i <= 150; i++) {
      const patient = samplePatients[i % samplePatients.length];
      patients.push({
        id: i,
        name: `${patient.name} ${i}`,
        gender: patient.gender,
        imgSrc: patient.imgSrc,
        code: `${patient.code}${i}`,
        date: `Jun ${(i % 30) + 1}, 2023`,
        time: `${(i % 12) + 1}:00 ${i % 2 === 0 ? "AM" : "PM"}`,
      });
    }
    return patients;
  };

  const [bookings, setBookings] = useState(generatePatients());
  const [currentPage, setCurrentPage] = useState(1);
  const [sortType, setSortType] = useState("A-Z");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedGender, setSelectedGender] = useState("All"); // Track selected gender
  const patientsPerPage = 50;

  const totalPages = Math.ceil(bookings.length / patientsPerPage);
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;

  const filteredPatients =
    selectedGender === "All"
      ? bookings
      : bookings.filter((patient) => patient.gender === selectedGender);
  const currentPatients = filteredPatients.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  );

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Updated handleGenderFilter function
  const handleGenderFilter = (gender) => {
    if (selectedGender === gender) {
      setSelectedGender("All");
    } else {
      setSelectedGender(gender);
    }
    setCurrentPage(1); // Reset to page 1 after filtering
  };

  const viewMore = (patient) => {
    setSelectedPatient(patient);
  };

  const closeModal = () => {
    setSelectedPatient(null);
  };

  const handleSortChange = (e) => {
    const selectedSort = e.target.value;
    setSortType(selectedSort);

    let sortedPatients;
    switch (selectedSort) {
      case "A-Z":
        sortedPatients = [...bookings].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        break;
      case "Z-A":
        sortedPatients = [...bookings].sort((a, b) =>
          b.name.localeCompare(a.name)
        );
        break;
      case "Date Ascending":
        sortedPatients = [...bookings].sort(
          (a, b) =>
            new Date(a.date + " " + a.time) - new Date(b.date + " " + b.time)
        );
        break;
      case "Date Descending":
        sortedPatients = [...bookings].sort(
          (a, b) =>
            new Date(b.date + " " + b.time) - new Date(a.date + " " + a.time)
        );
        break;
      default:
        break;
    }

    setBookings(sortedPatients);
  };

  return (
    <div className="patientBody">
      <div className="topnav">
        <div className="search-container">
          <form className="search-form">
             <div className="search-button">
              <i
                className="fa-solid fa-magnifying-glass"
                style={{ color: "#007bff" }}></i>
            </div>
            <input
              type="search"
              value=""
              placeholder="Search"
              class="search-input"
            />
           

            <div className="search-option">
              <div>
                <i
                  class="edit-pen-title fa fa-sort"
                  style={{ color: "#007bff" }}></i>
              </div>
              <div>
                <i
                  class="edit-pen-title fa fa-mars"
                  style={{ color: "#007bff" }}></i>
              </div>

              <div>
                <i
                  class="edit-pen-title fa fa-venus"
                  style={{ color: "#FF1493" }}></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="patientList">
        {currentPatients.map((booking, index) => (
          <div>
            <PatientInfo key={booking.id} {...booking}></PatientInfo>
          </div>
        ))}
        ;
      </div>

      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="paginationBtn"
          disabled={currentPage === 1}>
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="paginationBtn"
          disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}
