import React, { useEffect } from "react";
import { useSelector } from "react-redux"; // Access Redux state
import "./animations/animation.css";
import MainEnter from "./MainEnter/MainEnter";
import Login from "./Login/Login";

function App() {
  const { user } = useSelector((state) => state.auth); // Get authentication status from Redux state

  useEffect(() => {
    // You can load user info from localStorage or an API here, if necessary
    console.log("App loaded!");
  }, []);

  return (
    <>
      
    <MainEnter />
  </>
  );
}

export default App;
