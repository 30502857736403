import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format, addHours, startOfDay, isSameHour, isToday } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { toggleModal, setSelectedAppointment, setSelectedDate } from '../../redux/features/scheduleStore/calendarSlice';
import { az } from '../localization/az';

export const DayView = () => {
  const dispatch = useDispatch();
  const { selectedDate, appointments } = useSelector((state) => state.calendar);

  const hours = Array.from({ length: 24 }, (_, i) => addHours(startOfDay(selectedDate), i));

  const getAppointmentsForHour = (hour) => {
    return appointments.filter(app => isSameHour(new Date(app.date), hour));
  };

  const handlePrevDay = () => {
    dispatch(setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1))));
  };

  const handleNextDay = () => {
    dispatch(setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1))));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl font-bold text-gray-800">
            {format(selectedDate, 'dd MMMM yyyy')}
          </h2>
          {isToday(selectedDate) && (
            <span className="px-2 py-1 text-sm bg-blue-100 text-blue-800 rounded-full">
              Bu gün
            </span>
          )}
        </div>
        <div className="flex space-x-2">
          <button
            onClick={handlePrevDay}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Əvvəlki gün"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={handleNextDay}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Növbəti gün"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="space-y-1 overflow-auto max-h-[calc(100vh-200px)]">
        {hours.map(hour => {
          const hourAppointments = getAppointmentsForHour(hour);
          const isCurrentHour = isToday(selectedDate) && isSameHour(hour, new Date());

          return (
            <div
              key={hour.toString()}
              className={`
                flex border-l-4 pl-4 py-2 hover:bg-gray-50 transition-colors
                ${isCurrentHour ? 'border-blue-500' : 'border-gray-200'}
              `}
            >
              <div className="w-20 font-medium text-gray-600">
                {format(hour, 'HH:mm')}
              </div>

              <div className="flex-1 space-y-2">
                {hourAppointments.map(app => (
                  <button
                    key={app.id}
                    onClick={() => {
                      dispatch(setSelectedAppointment(app));
                      dispatch(toggleModal(true));
                    }}
                    className="w-full text-left p-3 rounded-lg bg-blue-100 hover:bg-blue-200 transition-colors group relative"
                  >
                    <div className="font-medium text-blue-800">
                      {app.patientName}
                    </div>
                    <div className="text-sm text-blue-600 flex items-center space-x-2">
                      <span>{format(new Date(app.date), 'HH:mm')}</span>
                      <span>•</span>
                      <span>{app.duration} dəq</span>
                      <span>•</span>
                      <span>{az.appointmentTypes[app.type]}</span>
                    </div>
                    {app.notes && (
                      <div className="mt-2 text-sm text-blue-600 line-clamp-2">
                        {app.notes}
                      </div>
                    )}
                  </button>
                ))}

                {hourAppointments.length === 0 && (
                  <button
                    onClick={() => {
                      dispatch(setSelectedDate(hour));
                      dispatch(toggleModal(true));
                    }}
                    className="w-full h-12 border-2 border-dashed border-gray-200 rounded-lg hover:border-blue-300 hover:bg-blue-50 transition-colors flex items-center justify-center text-sm text-gray-500 hover:text-blue-600"
                  >
                    Yeni görüş əlavə et
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
