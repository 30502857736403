import React from 'react';

function StatsCard({ title, value, icon, trend }) {
  const isPositive = trend.startsWith('+');

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5">
      <div className="flex items-center justify-between">
        <div className="p-2 bg-indigo-50 rounded-lg">
          {icon}
        </div>
        <span
          className={`text-sm font-medium ${
            isPositive ? 'text-green-600' : 'text-red-600'
          }`}
        >
          {trend}
        </span>
      </div>
      <h3 className="mt-4 text-2xl font-bold text-gray-900">{value}</h3>
      <p className="text-gray-500">{title}</p>
    </div>
  );
}

export default StatsCard;
