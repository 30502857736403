import { configureStore } from "@reduxjs/toolkit";
import bookingsReducer from "../features/dashboardStore/bookingsSlice";
import { filesSlice } from "../features/filePreviewerStore/filesSlice";
import calendarReducer from '../features/scheduleStore/calendarSlice';
import authReducer from '../features/authStore/authSlice'

export const store = configureStore({
  reducer: {
    bookings: bookingsReducer,
    files: filesSlice,
    calendar: calendarReducer,
    auth: authReducer,
  },
});
