import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  bookings: [],
};

export const getBookings = createAsyncThunk("getBookings", async () => {
  

  return [
    { id: 1, name: "Jane Cooper", date: "14 Jun 2023", time: "10:00 AM", age: 52 },
    { id: 2, name: "John Doe", date: "15 Jun 2023", time: "11:00 AM", age: 34 },
    { id: 3, name: "Emma Stone", date: "16 Jun 2023", time: "12:00 PM", age: 29 },
    { id: 4, name: "James Bond", date: "17 Jun 2023", time: "01:00 PM", age: 45 },
    { id: 5, name: "Lucy Liu", date: "18 Jun 2023", time: "02:00 PM", age: 39 },
    { id: 6, name: "Bruce Wayne", date: "19 Jun 2023", time: "03:00 PM", age: 40 },
    { id: 7, name: "Emma Stone", date: "16 Jun 2023", time: "12:00 PM", age: 29 },
    { id: 8, name: "James Bond", date: "17 Jun 2023", time: "01:00 PM", age: 45 },
    { id: 9, name: "Lucy Liu", date: "18 Jun 2023", time: "02:00 PM", age: 39 },
    { id: 10, name: "Bruce Wayne", date: "19 Jun 2023", time: "03:00 PM", age: 40 },
    { id: 11, name: "Emma Stone", date: "16 Jun 2023", time: "12:00 PM", age: 29 },
    { id: 12, name: "James Bond", date: "17 Jun 2023", time: "01:00 PM", age: 45 },
    { id: 13, name: "Lucy Liu", date: "18 Jun 2023", time: "02:00 PM", age: 39 },
    { id: 14, name: "Bruce Wayne", date: "19 Jun 2023", time: "03:00 PM", age: 40 },
  ];
});

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBookings.fulfilled, (state, action) => {
      console.log("--------------------------------")
      console.log(action.payload);
      state.bookings = action.payload;
    });
  },
});

export default bookingsSlice.reducer;
