// src/DocumentModal.js
import React from "react";
import "./DocumentModal.css";
import FilePreviewer from "./FilePreviewer";

export default function DocumentModal({ onClose, onFileSelect }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        

        <div className="information-section">
          <div className="modal-info">
            <div className="modal-inputs">
              <label for="ta">Basliq</label>
              <input placeholder="Basliq..." id="ta" type="text"></input>
            </div>

            <div className="modal-inputs">
              <label for="ta">Aciqlama</label>
              <textarea id="ta" name="ta" placeholder="Aciqlama..."></textarea>
            </div>
          </div>
          <div className="modal-buttons">
            <button>Save</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
        <div className="file-section">
          
          <FilePreviewer></FilePreviewer>
        </div>
      </div>
    </div>
  );
}
