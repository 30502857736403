import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appointments: [],
  selectedDate: new Date(),
  view: 'month',
  isModalOpen: false,
  selectedAppointment: null,
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    addAppointment: (state, action) => {
      state.appointments.push(action.payload);
    },
    removeAppointment: (state, action) => {
      state.appointments = state.appointments.filter(app => app.id !== action.payload);
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    toggleModal: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setSelectedAppointment: (state, action) => {
      state.selectedAppointment = action.payload;
    },
  },
});

export const {
  addAppointment,
  removeAppointment,
  setSelectedDate,
  setView,
  toggleModal,
  setSelectedAppointment,
} = calendarSlice.actions;

export default calendarSlice.reducer;
