import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import {
  FaTachometerAlt,
  FaUserFriends,
  FaCalendarAlt,
  FaCreditCard,
  FaRegClock,
  FaCog,
  FaBars,
  FaBell,
  FaSignOutAlt,
  FaChartBar,
  FaUser,
  FaChevronDown,
  FaChevronUp,
  FaStethoscope,
} from "react-icons/fa";

import "../App.css";
import "./MainEnter.css"
import photo from "../images/drphoto.jpg";
import aze from "../images/azerbaijan.png";
import ru from "../images/russian-federation.png";
import eng from "../images/great-britain.png";
import logo from "../images/icon.png";

import Dashboard from "../Dashboard/Dashboard";
import PatientDetails from "../PatientDetails/PatientDetails";
import Schedule from "../Schedule/Schedule";
import Payments from "../Payments/Payments";
import Settings from "../Settings/Settings";
import Summary from "../Summary/Summary";
import Patients from "../Patients/Patients";
import Loading from "../Loading/Loading";

import "../animations/animation.css";

function MainEnter() {
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenAvatar, setDropdownOpenAvatar] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [loading, setLoading] = useState(true); // State to control loader
  const [showSummary, setShowSummary] = useState(false);
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdownAvatar = () => {
    setDropdownOpenAvatar(!dropdownOpenAvatar);
  };
  const toggleSummary = () => {
    setShowSummary(!showSummary); // Toggle the visibility of the Summary component
  };
  useEffect(() => {
    // Hide loader after 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="con-1">
        <img src={logo} alt="logo" />

        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }>
          <FaTachometerAlt className="menu-icon" /> Dashboard
        </NavLink>

        <NavLink
          to="/loading"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }>
          <FaUserFriends className="menu-icon" /> Chat
        </NavLink>

        <NavLink
          to="/payments"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }>
          <FaCreditCard className="menu-icon" /> Payments
        </NavLink>

        <NavLink
          to="/schedule"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }>
          <FaRegClock className="menu-icon" /> Schedule
        </NavLink>

        <NavLink
          to="/patients"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }>
          <FaStethoscope className="menu-icon" /> Patients
        </NavLink>

        <NavLink
          to="/settings"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }>
          <FaCog className="menu-icon" /> Settings
        </NavLink>
      </div>

      <div className="con-2">
        <div className="navbar">
          <div className="navbar-options">
            <div className="language-dropdown">
              <button className="dropdown-button" onClick={toggleDropdown}>
                <span className="globe-icon">🌐</span> English{" "}
                <span className="arrow-icon">
                  {dropdownOpen ? (
                    <FaChevronUp style={{ color: "white", marginTop: "5px" }} />
                  ) : (
                    <FaChevronDown
                      style={{ color: "white", marginTop: "5px" }}
                    />
                  )}
                </span>
              </button>
              {dropdownOpen && (
                <div className="dropdown-content">
                  <div className="dropdown-item">
                    <img src={aze} alt="Aze" className="flag-icon" />
                    Azerbaijani
                  </div>
                  <div className="dropdown-item">
                    <img src={ru} alt="Russian" className="flag-icon" />
                    Russian
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="small-language-menu">
            <div className="language-dropdown-small">
              <button
                className="dropdown-button-small"
                onClick={toggleDropdown}>
                <img alt="eng" src={eng} className="flag-icon-small"></img>
                <span>
                  {dropdownOpen ? (
                    <FaChevronUp className="arrow-icon-small" />
                  ) : (
                    <FaChevronDown className="arrow-icon-small" />
                  )}
                </span>
              </button>
              {dropdownOpen && (
                <div className="dropdown-content-small">
                  <div className="dropdown-item-small">
                    <img src={aze} alt="Aze" className="flag-icon-small" />
                  </div>
                  <div className="dropdown-item-small">
                    <img src={ru} alt="Russian" className="flag-icon-small" />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="navbar-icons">
            <div className="navbar-bell">
              <FaBell className="navbar-icon" />
              <FaSignOutAlt className="navbar-icon" />
            </div>
            <button className="avatar" onClick={toggleDropdownAvatar}>
              <img alt="avatar" src={photo}></img>
              {dropdownOpenAvatar && (
                <div className="dropdown-content-avatar">
                  <div className="dropdown-item">
                    <NavLink to="/settings" className={"dropdown-navlink"}>
                      <FaUser className="menu-icon" /> View Profile
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to={"/signout"} className="dropdown-navlink">
                      <FaSignOutAlt className="menu-icon" /> Sign Out
                    </NavLink>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>

        <div className="bottom-nav">
          <NavLink to="/chat" className="bottom-nav-item">
            <FaUserFriends className="menu-icon" />
            <span>Chat</span>
          </NavLink>
          <NavLink to="/patients" className="bottom-nav-item">
            <FaStethoscope className="menu-icon" />
            <span>Patient</span>
          </NavLink>
          <NavLink to="/payments" className="bottom-nav-item">
            <FaCreditCard className="menu-icon" />
            <span>Payments</span>
          </NavLink>
          <NavLink to="/summary" className="bottom-nav-item">
            <FaChartBar className="menu-icon" />
            <span>Summary</span>
          </NavLink>
          <div
            className="bottom-nav-item"
            onClick={() => setShowMoreMenu(!showMoreMenu)}>
            <FaBars className="menu-icon" />
            <span>More</span>
          </div>
        </div>

        <div className={`more-menu ${showMoreMenu ? "show" : ""}`}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "more-menu-item active" : "more-menu-item"
            }>
            <FaTachometerAlt className="menu-icon" /> Dashboard
          </NavLink>
          <NavLink
            to="/schedule"
            className={({ isActive }) =>
              isActive ? "more-menu-item active" : "more-menu-item"
            }>
            <FaRegClock className="menu-icon" /> Schedule
          </NavLink>
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive ? "more-menu-item active" : "more-menu-item"
            }>
            <FaCog className="menu-icon" /> Settings
          </NavLink>
        </div>

        <div className="content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patients/:id" element={<PatientDetails />} />
          </Routes>
        </div>
      </div>



      <button
        onClick={toggleSummary}
        className="showsummarybtn fixed bottom-2 right-5 border-2 border-blue-500 text-blue-500 p-4 pl-3 pr-3 rounded-full shadow-lg hover:bg-blue-700 hover:text-white transition-all backdrop-blur-sm bg-white-900"
      >
        {showSummary ? (
          <i className="fas fa-arrow-left"></i> // Sol ok ikonu
        ) : (
          <i className="fas fa-arrow-right"></i> // Sağ ok ikonu
        )}
      </button>






      {/* Button to toggle Summary visibility */}
      <div className={`con-3 ${showSummary ? "slide-in-blurred-right" : "slide-out-right"}`} >
        <div>
          <Summary />
        </div>
      </div>
    </Router>
  );
}

export default MainEnter;
