import React, { useEffect, useRef } from 'react';

const MonthlyPatientChart = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        // Dynamically load Chart.js from the CDN
        const script = document.createElement('script');
        script.src = "https://cdn.jsdelivr.net/npm/chart.js";
        script.async = true;

        script.onload = () => {
            const ctx = canvasRef.current.getContext('2d');
            const chart = new window.Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [
                        'January', 'February', 'March', 'April', 'May', 'June',
                        'July', 'August', 'September', 'October', 'November', 'December'
                    ],
                    datasets: [{
                        label: 'Patients',
                        data: [120, 140, 150, 130, 160, 170, 180, 190, 200, 220, 230, 240],
                        backgroundColor: '#007bff',
                        borderColor: '#007bff',
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });

            // Cleanup function to destroy the chart on component unmount
            return () => {
                chart.destroy();
            };
        };

        document.body.appendChild(script);

        // Cleanup: Remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="max-w-lg w-full bg-white rounded-lg shadow p-4 md:p-6 h-500 mt-10">
            <div className="flex justify-between pb-4 mb-4 border-b border-gray-200">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-lg bg-gray-100 flex items-center justify-center me-3">
                        <svg
                            className="w-6 h-6 text-gray-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 19"
                        >
                            <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                            <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />
                        </svg>
                    </div>
                    <div>
                        <h5 className="leading-none text-2xl font-bold text-gray-900 pb-1">3.4k</h5>
                        <p className="text-sm font-normal text-gray-500">Patients this month</p>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2">
                <dl className="flex items-center">
                    <dt className="text-gray-500 text-sm font-normal me-1">Visits:</dt>
                    <dd className="text-gray-900 text-sm font-semibold">1,200</dd>
                </dl>
                <dl className="flex items-center justify-end">
                    <dt className="text-gray-500 text-sm font-normal me-1">Conversion rate:</dt>
                    <dd className="text-gray-900 text-sm font-semibold">10%</dd>
                </dl>
            </div>

            <canvas ref={canvasRef}></canvas>
        </div>
    );
};

export default MonthlyPatientChart;